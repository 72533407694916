<template>
  <van-grid :column-num="1" style="height: 100vh">
    <van-grid-item v-if="loading">
      <van-loading color="#1989fa" size="48"/>
    </van-grid-item>
    <van-grid-item v-if="!loading">无租户信息</van-grid-item>
  </van-grid>
</template>

<script>
import {useRoute, useRouter} from 'vue-router'
import {ref} from "vue";

/**
 * 预检页面
 * @description 检查 URL 中的各项参数，根据参数决定跳转到具体的导航页面
 * @author Wayne Carder
 */
export default {
  name: 'Index',
  mounted() {
    const urlParamStr = window.location.search;
    if (!urlParamStr) {
      this.loading = false
      return
    }
    const urlParams = new URLSearchParams(urlParamStr)
    const tenant = urlParams.get("tenant") || "xcfw";
    console.log("tenant", tenant)
    if (!tenant) {
      this.loading = false
      return
    }
    const id = urlParams.get("id");
    const query = {tenant}
    if (!id) {
      useRouter().replace({path: '/navigation-without-device', query})
    } else {
      query.id = id
      useRouter().replace({path: '/navigation', query})
    }
  },
  data: () => ({
    loading: true
  })
}
</script>
